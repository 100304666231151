.edit_block {
  width: 100%;
  position: relative;
  div[class*="_more_block"] {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #fff5;
    border-radius: 50%;
    .add_types {
      width: max-content;
      display: grid;
      padding: 5px;
      position: absolute;
      align-items: center;
      background-color: white;
      border-radius: 8px;
      .type_block {
        svg {
          width: 44px;
          margin: 5px;
          padding: 8px;
          background: #ddd;
          border-radius: 8px;
        }
      }
    }
    &[class*="before"] {
      transform: translate(-50%, -50%);
      .add_types {
        bottom: 32px;
      }
    }
    &[class*="after"] {
      bottom: 0;
      transform: translate(-50%, 50%);
      .add_types {
        top: 32px;
      }
    }
  }
  &:has(div[class*="inner"]) {
    display: flex;
    align-items: center;
    justify-content: center;
    > div[class*="inner"] {
      width: 100%;
      height: 100%;
      padding: 48px 0;
      background-color: transparent;
    }
  }
  .block_empty {
    display: flex;
    justify-content: center;
    padding: 36px;
    div[class*="_more_block"] {
      .add_types {
        background: transparent;
      }
    }
  }
  div[class*="_more_button"] {
    width: 28px;
    height: 28px;
    display: none;
    justify-content: center;
    margin: auto;
    position: absolute;
    left: 50%;
    &[class*="before"] {
      top: 0;
    }
    &[class*="after"] {
      bottom: 0;
    }
  }
  &.hovered {
    outline: 1px solid #ccc5;
    cursor: pointer;
    background: #fff1;
    > div[class*="_more_button"] {
      display: flex;
      z-index: 800;
    }
  }
  &.selected {
    background: #fff1;
    > div[class*="_more_button"] {
      display: flex;
      z-index: 1000;
      div[class*="_more_block"] {
        background: #fff5;
      }
    }
  }
}
