@forward "variables";
@forward "font";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Carbona";
  line-height: 1em;
  scroll-behavior: smooth;
}

h1 {
  font-size: 2.3em;
  font-weight: 700;
}

h2 {
  font-size: 1.9em;
  font-weight: 700;
}

h3 {
  font-size: 1.4em;
  font-weight: 700;
}

h4 {
  font-size: 1em;
  font-weight: 700;
}

h5 {
  font-size: 0.9em;
  font-weight: 700;
}

h6 {
  font-size: 0.83em;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
}

.simple-button {
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  margin: auto;
  font-weight: 700;
}

.button_option {
  width: 48px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: #f00;
      }
    }
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}
