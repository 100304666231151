@mixin form {
  .field {
    margin-bottom: 13px;
    .name_input {
      margin-bottom: 0.5em;
      label {
        font-size: 1.2em;
      }
    }

    input {
      width: 100%;
      border-radius: 55px;
      padding: 13px 21px;
      transition: 0.2s ease-out;
      &:focus-visible {
        outline: none;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1.3em;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button {
      width: -moz-fit-content;
      width: fit-content;
      padding: 13px 21px;
      border-radius: 55px;
      font-size: 1.16em;
      font-weight: 600;
      line-height: 1.15em;
      text-transform: uppercase;
      cursor: pointer;
      transition: 0.3s ease-out;
      &:hover {
        background: transparent;
      }
    }
    .reset {
      cursor: pointer;
      a {
        color: #fff;
        font-weight: 300;
      }
    }
  }

  @media (orientation: portrait) {
    .field {
      .name_input {
        label {
          font-size: 1em;
        }
      }

      input {
        padding: 8px 13px ;
        &:focus-visible {
          outline: none;
        }
      }
      &:not(:last-child) {
        margin-bottom: 1.3em;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        padding: 8px 13px;
        font-size: 1em;
        line-height: 1em;
        color: #fff;
        &:hover {
          background: transparent;
          color: #052d91;
        }
      }
    }
  }
}
