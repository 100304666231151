@import "src/styles/variables";

.left_bu {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &.left_home {
    width: calc($column-grid-width * 3);
    .top_left {
      margin: 0 21px;
      padding: 55px $border-grid-width-fina;
    }
    .informationPage {
      margin: 0 $border-grid-width-fina;
      padding: 21px 21px 55px;
    }
  }
  &.left_section {
    width: calc($column-grid-width * 2.1);
    .top_left {
      margin: 0 21px;
      padding: 55px $border-grid-width-fina;
    }
    .informationPage {
      height: 80vh;
      overflow: auto;
      margin: 0 $border-grid-width-fina 0;
      padding: 21px 17px 21px 34px;
    }
  }
  .top_left {
    height: 0;
    display: flex;
    align-items: center;
    .bu_logo {
      display: flex;
      align-items: center;
      a {
        display: block;
        height: 100%;
        svg {
          height: 100%;
        }
        img{
          max-width: 150px;
        }
      }
    }
  }
  .bu_left_content {
    display: flex;
    flex: 1;
    position: relative;
  }
  .informationPage {
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - $bu-space-top);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    transition: 0.05s ease-out;
    .informations {
      .title {
        margin-bottom: $bu-space-box-4;
        word-wrap: break-word;
        font-weight: 400;
      }
      .sub_title {
        color: var(--bu-secondary);
        margin-bottom: $bu-space-box-3;
        font-weight: 500;
      }
      .inside {
        margin-bottom: $bu-space-box-3;
        p {
          line-height: 1.5em;
          margin-bottom: $bu-space-box-1;
        }
      }
    }
    .modules,
    .downloads {
      margin-bottom: 34px;
      .module,
      .download {
        margin-bottom: 8px;
        svg {
        }
        a {
          text-decoration: none;
          b {
            font-weight: 500;
          }
        }
      }
    }
    .downloads {
      .download {
        display: flex;
        align-items: center;
        padding: 8px 13px;
        background: #e6e6e6;
        text-align: center;
        border-radius: 21px;
        svg {
          width: 28px;
          margin-right: 13px;
        }
        a {
          font-weight: 500;
        }
      }
    }
  }
}

.right_bu {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.right_home {
    width: calc($column-grid-width * 5);
    .top_right {
      padding: 55px $border-grid-width;
    }
    .informationPage {
      padding: 0 $border-grid-width;
    }
  }
  &.right_section {
    width: calc($column-grid-width * 6);
    .top_right {
      padding: 55px $border-grid-width-fina;
    }
    .informationPage {
      padding: 0 $border-grid-width-fina;
    }
  }

  .top_right {
    height: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .bu_logo {
      display: flex;
      align-items: center;
      svg {
        height: 100%;
      }
    }
    .control_pages {
      display: flex;
      align-items: center;
      position: absolute;
      left: $bu-space-box-3;
      bottom: 0;
      .prev_page,
      .next_page {
        display: flex;
        padding: 2px 13px;
        cursor: pointer;
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #000;
          p {
            font-size: 13px;
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .prev_page {
        margin-right: 2px;
        padding-right: 34px;
        border-radius: 8px 0 0 0;
        svg {
          margin-right: 5px;
        }
      }
      .next_page {
        justify-content: flex-end;
        padding-left: 34px;
        border-radius: 0 8px 0 0;
        svg {
          margin-left: 3px;
        }
      }
      .options_page {
        > * {
          margin: 0 13px;
          cursor: pointer;
        }
        .print {
          width: 21px;
        }
      }
    }
  }
  .bu_right_content {
    flex: 1;
    display: flex;
    overflow: hidden;
    > * {
      width: 100%;
    }
  }
}

@media (orientation: portrait) {
  .left_bu {
    min-height: auto;
    &.left_home {
      width: 100%;
      .top_left {
        margin: 0 21px;
        padding: 55px $border-grid-width-fina;
      }
      .informationPage {
        min-height: auto !important;
        margin: 0;
        margin-left: $border-grid-width-fina;
        padding: 21px 21px 55px;
      }
    }
    &.left_section {
      width: 100%;
      .top_left {
        margin: 0 21px;
        padding: 55px $border-grid-width-fina;
      }
      .informationPage {
        min-height: auto !important;
        margin: 0;
        margin-left: $border-grid-width-fina;
        padding: 21px 34px;
      }
    }
    .bu_left_content {
      display: flex;
      flex: 1;
      position: relative;
    }
    .informationPage {
      width: 100%;
      height: fit-content;
      min-height: calc(100vh - $bu-space-top);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      transition: 0.05s ease-out;
      .informations {
        .title {
          margin-bottom: $bu-space-box-2;
          word-wrap: break-word;
          font-weight: 400;
        }
        .sub_title {
          color: var(--bu-secondary);
          margin-bottom: $bu-space-box-3;
          font-weight: 500;
        }
        .inside {
          margin-bottom: $bu-space-box-3;
          p {
            line-height: 1.5em;
            margin-bottom: $bu-space-box-1;
          }
        }
      }
      .modules,
      .downloads {
        margin-bottom: 34px;
        .module,
        .download {
          margin-bottom: 8px;
          svg {
          }
          a {
            text-decoration: none;
            b {
              font-weight: 500;
            }
          }
        }
      }  
    }
  }
  
  .right_bu {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  
    &.right_home {
      width: 100%;
      .top_right {
        padding: 21px $border-grid-width;
        margin-left: $border-grid-width-fina;
      }
    }
    &.right_section {
      width: 100%;
      .top_right {
        padding: 21px $border-grid-width-fina;
        margin-left: $border-grid-width-fina;
      }
    }
    .top_right {
      .bu_logo {
        display: none;
      }
      .control_pages {
        display: flex;
        align-items: center;
        position: absolute;
        left: $bu-space-box-3;
        bottom: 0;
        .prev_page,
        .next_page {
          display: flex;
          padding: 2px 13px;
          cursor: pointer;
          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #000;
            p {
              font-size: 13px;
            }
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
        .prev_page {
          margin-right: 2px;
          padding-right: 34px;
          border-radius: 8px 0 0 0;
          svg {
            margin-right: 5px;
          }
        }
        .next_page {
          justify-content: flex-end;
          padding-left: 34px;
          border-radius: 0 8px 0 0;
          svg {
            margin-left: 3px;
          }
        }
        .options_page {
          > * {
            margin: 0 13px;
            cursor: pointer;
          }
          .print {
            width: 21px;
          }
        }
      }
    }
    .bu_right_content {
      margin: 0;
      margin-left: calc($border-grid-width-fina + 14px ) ;
      overflow: hidden;
    }
  }
  
}

