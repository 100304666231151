$cinza-e-1: #37474f;
$cinza-e-2: #78909c;
$cinza-e-3: #90a4ae;
$cinza-c-1: #cfd8dc;
$cinza-c-2: #eceff1;
$roxo: #9e0eff;
$roxo-a: #a671ff;
$rosa: #ce08eb;
$azul: #0c1e8c;
$turquesa: #00bfb3;

$bu-gray: #e6e6e6;
$border-grid-width: calc(100vw * 0.0625);
$border-grid-height: calc(100vh * 0.0833);
$border-grid-width-fina: calc(100vh * 0.062);
$border-grid-height-fina: calc(100vh * 0.056);
$column-grid-width: calc(100vw / 8);
$column-grid-height: calc(100vh / 6);
$bu-space-box-1: 13px;
$bu-space-box-2: 21px;
$bu-space-box-3: 34px;
$bu-space-box-4: 55px;
$bu-space-top: 110px;

// @if (orientation: portrait) {
//   $border-grid-width: calc($border-grid-width / 2);
//   $border-grid-height: calc($border-grid-height / 2);
//   $border-grid-width-fina: calc($border-grid-width-fina / 2);
//   $border-grid-height-fina: calc($border-grid-height-fina / 2);
//   $column-grid-width: calc($column-grid-width / 2);
//   $column-grid-height: calc($column-grid-height / 2);
//   $bu-space-box-1: 8px;
//   $bu-space-box-2: 13px;
//   $bu-space-box-3: 21px;
//   $bu-space-box-4: 34px;
//   $bu-space-top: 55px;
// }
