.containers {
    .inner {
      height: 100%;
      > * {
        height: 100%;
        min-height: calc(100vh - 110px);
      }
      .downloads {
        padding: 55px;
        background: #f9f9f9;
        .folder_download {
          &:not(:last-child) {
            margin-bottom: 89px;
          }
          .folder_download_title {
            margin-bottom: 34px;
          }
          .folder_download_content {
            display: flex;
            flex-wrap: wrap;
            gap: 21px;
            .download_item {
              width: 233px;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              padding: 21px 13px;
              background: #eee;
              border-radius: 13px;
              .icon_type_download {
                width: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  width: 100%;
                }
              }
              .name_download {
                width: 100%;
                margin-left: 13px;
                font-size: 0.85em;
                text-overflow: ellipsis;
              }
              .date_download {
                font-size: 0.7em;
              }
            }
          }
        }
      }
    }
  }
  