@import "src/styles/mixins";

.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  > * {
    flex: 1;
  }
  .acesso {
    display: flex;
    flex-direction: column;
    align-items: center;
    .brands {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 34px 55px;
      .brand {
        img {
          width: 144px;
        }
      }
      .brand_universe {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 13px 0;
        .name {
          > * {
            width: 8px;
            font-size: 3em;
            font-weight: 400;
          }
        }
      }
    }
    .form_content {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 55px;
      form {
        width: 89%;
        @include form;
        .field {
          input {
            border: 1px solid;
            background: transparent;
            &.error {
              border: red 1px solid;
              &:focus-visible {
                outline: red auto 1px;
              }
            }
            &:focus {
              border: 1px solid #fff;
            }
          }
          &.error {
            margin-bottom: 0;
            text-align: center;
            color: red;
            p {
              font-size: 14px;
            }
          }
        }
        .remember {
          justify-content: center;
          margin-top: 43px;
          margin-bottom: 24px;
        }
      }
      .footer_login {
        margin-top: 2em;
        font-size: 0.85em;
      }
    }
  }
  .image {
    overflow: hidden;
    figure {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (orientation: portrait) {
  .login {
    .acesso {
      .brands {
        padding: 21px;
        .brand_universe {
          .name {
            > * {
              width: 100%;
              font-size: 2em;
            }
          }
        }
      }
      .form_content {
        flex: 2;
        padding: 34px 21px;
      }
    }
    .image {
      display: none;
    }
  }
}