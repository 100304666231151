@import "src/styles/variables";

.containers {
  height: 100%;
  *:target {
    scroll-margin-top: calc($bu-space-top) / 2;
  }
  .inner {
    height: 100%;
    .module_box {
      height: 100%;
      min-height: calc(100vh - 110px);
    }
  }
}
