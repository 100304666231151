.box_children {
  height: 85%;
  width: 100%;
  display: grid;
  grid-auto-rows: minmax(50%, auto);
  a {
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .name {
      position: absolute;
    }
    .select {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 18px;
      border: 1px dashed #999;
      font-weight: 600;
    }
  }
}
