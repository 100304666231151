$icon_menu_width: 28px;
@import "src/styles/variables";

@supports (backdrop-filter: blur(8px)) {
  .bu_menu {
    backdrop-filter: blur(8px);
  }
}

.bu_menu {
  width: calc($column-grid-width * 1.5);
  height: 100vh;
  flex: 1;
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  padding: $bu-space-box-2 $bu-space-box-1;
  padding-left: $bu-space-box-3;
  background: #e6e6e6cc;
  border-radius: 0 $bu-space-box-2 0 0;
  transform: translateX(calc(-100% + $icon_menu_width + $bu-space-box-2));
  transition: 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.2s ease-in-out;
  .top_menu {
    width: 100%;
    height: 80%;
    .action_menu {
      display: flex;
      justify-content: flex-end;
      .icon_menu {
        width: $icon_menu_width;
      }
    }
    .items {
      .item {
        width: calc(100% - ($icon_menu_width * 2));
        margin-bottom: $bu-space-box-1;

        cursor: pointer;
        > .item_name {
          padding: 5px 0;
          margin: 5px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          a {
            width: -moz-fit-content;
            width: fit-content;
            display: block;
            color: #000;
            text-decoration: none;
            overflow: hidden;
            p {
              height: 18px;
              font-weight: 700;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          .item_name_arrow {
            width: 21px;
            margin-right: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: 0.09s ease-in-out;
          }
        }
      }
      .sub_item {
        margin-bottom: $bu-space-box-3;
        cursor: pointer;
        .sub_item_name {
          padding: 5px 0;
          margin: 5px 0;
          a {
            width: 100%;
            display: block;
            color: #000;
            text-decoration: none;
            p {
              height: 18px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  &.active {
    transform: translateX(0);
    overflow-y: auto;
    .top_menu {
      .icon_menu {
        .line1 {
          transform: translate(30px, -5px) rotate(45deg);
        }
        .line2 {
          stroke: transparent;
          transition-delay: 0s;
        }
        .line3 {
          transform: translate(-20px, -33px) rotate(-45deg);
          transform-origin: 100% 50%;
        }
      }
    }
    .items {
      .item {
        width: 100%;
        @for $i from 0 to 50 {
          &:nth-child(#{$i}) {
            > .item_name {
              .item_name_arrow {
                margin-right: 0px;
                opacity: 1;
                transition-delay: 0.1s * $i;
              }
            }
          }
        }
      }
    }
  }
  .bottom_menu {
    width: 100%;
    align-self: flex-end;
    .logout_menu {
      cursor: pointer;
      p {
        font-weight: bold;
      }
    }
  }
}

@media (orientation: portrait){
  .bu_menu {
    width: 80%;
    max-width: 360px;
    z-index: 1000;
    .top_menu {
      width: 100%;
      height: 80%;
      .action_menu {
        display: flex;
        justify-content: flex-end;
        .icon_menu {
          width: $icon_menu_width;
        }
      }
      .items {
        .item {
          width: calc(100% - ($icon_menu_width * 2));
          margin-bottom: $bu-space-box-1;
  
          cursor: pointer;
          > .item_name {
            padding: 5px 0;
            margin: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
              width: -moz-fit-content;
              width: fit-content;
              display: block;
              color: #000;
              text-decoration: none;
              overflow: hidden;
              p {
                height: 18px;
                font-weight: 700;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            .item_name_arrow {
              width: 21px;
              margin-right: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0;
              transition: 0.09s ease-in-out;
            }
          }
        }
        .sub_item {
          margin-bottom: $bu-space-box-3;
          cursor: pointer;
          .sub_item_name {
            padding: 5px 0;
            margin: 5px 0;
            a {
              width: 100%;
              display: block;
              color: #000;
              text-decoration: none;
              p {
                height: 18px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    &.active {
      transform: translateX(0);
      .top_menu {
        .icon_menu {
          .line1 {
            transform: translate(30px, -5px) rotate(45deg);
          }
          .line2 {
            stroke: transparent;
            transition-delay: 0s;
          }
          .line3 {
            transform: translate(-20px, -33px) rotate(-45deg);
            transform-origin: 100% 50%;
          }
        }
      }
      .items {
        .item {
          width: 100%;
          @for $i from 0 to 50 {
            &:nth-child(#{$i}) {
              > .item_name {
                .item_name_arrow {
                  margin-right: 0px;
                  opacity: 1;
                  transition-delay: 0.1s * $i;
                }
              }
            }
          }
        }
      }
    }
    .bottom_menu {
      width: 100%;
      align-self: flex-end;
      .logout_menu {
        cursor: pointer;
        p {
          font-weight: bold;
        }
      }
    }
  }
}

