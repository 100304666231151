.containers {
  .items {
    flex: 1;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    .item {
      width: calc(100% / 3);
      height: calc(100% / 2);
      a {
        display: block;
        height: 100%;
        position: relative;
        color: #fff;
        text-transform: uppercase;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        p {
          width: 100%;
          padding: 21px;
          position: absolute;
          bottom: 0;
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .containers {
    .items {
      height: 100%;
      .item {
        width: 50%;
        aspect-ratio: 4/ 3;
        min-height: auto;
        a {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
